@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
  a{
    @apply text-[#fff] text-sm cursor-pointer
  }
  html{
    font-family: 'Roboto', sans-serif;
    };

   
}
@layer components {
  .heading  {
    @apply text-[#3a7700];
  }
  .btn  {
    @apply bg-[#003356] text-[#f1f7fd]
  }
}

/* text-[#f1f7fd] bg-[#003356]  */

body {
  max-width: 100vw;
  overflow-x:hidden ;
  background-color:rgb(241, 247, 253)
  
  /*  */
}
/* .firstSection{
background-image: url(./assets/spiralbg.png);
background-position: 0 0;
background-size: contain;
background-repeat: no-repeat;
} */
.faq{
background-image: url(./assets/spiralbg.png);
background-position: right;
background-size: contain;
background-repeat: no-repeat;
}



.bg-image{
  background: url(./assets/Bitmap.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; 
}
#search {
  background-image: linear-gradient(90deg,#3a7700,#003356);
}

.slide-out {
  animation: slide-out 300ms ease linear ;
} 
.slide-in{
  height: 0;
} 

@keyframes slide-out {
  0%{
    height: 0;
  }

  100%{
    height: fit-content;
  }
}

